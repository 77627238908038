<template>
	<div class="">
		<img src="./images/banner@1x.png" style="width: 100%;" >
		<div class="xf">
			<div class="mains">
				<h3>登录</h3>
				<p>用户名</p>
				<input type="text" v-model="mobile" name="" id="" value="" />
				<p>登录密码</p>
				<input type="password" v-model="password" name="" id="" value="" />
				
				<div class="xy">
					<input  v-model="isQRCodeTrack" :true-value="1":false-value="0"  v-on:click="CheckItem()" id="isQRCodeTrack"  type="checkbox">
					<p>阅读并同意<span @click="popup">《国晟普惠用户信息隐私协议》</span></p>
				</div>
				<button @click="login()">登录</button>
				<i>没有账号？<router-link to='/register'><span>去注册</span></router-link></i>
			</div>
			<div class="popup" v-show="hide" @click="close">
				内容内容内容内容内容内容内容内容
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				password:'',
				mobile:'',
				isQRCodeTrack:false,
				hide:false
				
			}
		},
		created(){
			console.log(111)
		
		},
		
		methods:{
			close(){
				this.hide = false
			},
			popup(){
				this.hide = true
			},
			CheckItem(){
				this.isQRCodeTrack = !this.isQRCodeTrack
				console.log(this.isQRCodeTrack)
			},
			login(){
				var that = this
				if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile))){
					alert('请输入正确手机号')
					return
				}
				if(!this.password){
					alert('请输入密码')
					return
				}
				if(this.isQRCodeTrack==false){
					alert('请勾选协议内容')
					return
				}
				
				this.$axios.post('/cms/login/officialWebsite',{mobile:this.mobile,password:this.password})
				.then(function (res) {
					console.log(res);
					if(res.data.head.retFlag=='00000'){
						
						alert('登录成功')
						sessionStorage.setItem('token',res.data.body.token);
						that.$router.push('/index');
						
					}else if(res.data.head.retFlag=='E000202'){
						alert(res.data.head.retMsg)
		
					}else if(res.data.head.retFlag=='E000201'){
						alert(res.data.head.retMsg)
					}
					
				})
		
			}
		}
	}
</script>

<style>
	.popup{
		width:46%;
		height: 84%;
		background-color: #fff;
		border-radius: 5px;
		position: absolute;
		left: 50%;
		top: 5%;
		transform: translateX(-50%);
		overflow: scroll;
		padding: 2%;
	}
	i{
		font-size: 12px;
		color: #666;
	}
	i span{
		color: #ea0029;
		font-size: 12px;
	}
	button{
		background-color: #ea0029;
		color: #fff;
		padding: 10px 35px;
		border: none;
		margin-top: 20px;
		border-radius: 5px;
	}
	.xy input{
		width: 20px;
		margin-top: 10px;
	}
	.xy p{
		color: #666;
		margin: 0 10px;
		font-size: 12px;
	}
	.xy p span{
		color: #ea0029;
		cursor: pointer;
	}
	.xy{
		display: flex;
		align-items: center;
	}
	input{
		outline:none ;
		width: 100%;
		margin-bottom: 10px;
		border: none;
		padding: 10px;
		border-bottom: 1px solid #ccc;
	}
	.xf .mains>p{
		font-size: 14px;
		margin: 10px 0;
	}
	.xf .mains h3{
		margin-bottom: 13%;
		font-size: 24px;
		font-weight: normal;
	}
	.xf{
		background: rgba(0,0,0,0.5);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 9;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.xf .mains{
		text-align: left;
		background-color: #fff;
		width: 450px;
		padding: 30px;
		
	}
	.xf .mains i{
		display: block;
		font-style: normal;
		margin-top: 20px;
	}
</style>
